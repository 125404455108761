import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ItchioLogo, SteamLogo } from "../components/svgs/logo"

const BlogIndex = ({ data, location }) => {
  //const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location}>
      <SEO title="Early Morning Indie Game Developer" />
      <div className="hero text-center container">
        <h1>Early morning indie devs with a passion for fun, inclusivity, and great games.</h1>        
      </div>
      <div id="owgtowg" className="inner-shadow banner-game-preview">
        <div className="container hero hero-columns">
          <div className="two-col">
            <Link to="/owgtowg">
              <Img
                alt="Open World Game: the Open World Game logo"
                fluid={data.owgtowg.childImageSharp.fluid}
              />
            </Link>
          </div>
          <div className="two-col flex-center-center">
            <Link to="/owgtowg" className="two-row top-row">
              <p>The purest open world game experience.</p>
            </Link>
            <div className="store-logos two-row bottom-row">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://store.steampowered.com/app/1144110/Open_World_Game_the_Open_World_Game/"
              >
                <SteamLogo fill="#eee" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://morningpersongames.itch.io/owgtowg"
              >
                <ItchioLogo fill="#eee" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center recent-news">
        <h2 className="border-gradient-separator border-gradient-sunrise">
          Recent News
        </h2>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <div className="container">
                <Link
                  className="preview-image show-on-desktop"
                  to={node.fields.slug}
                >
                  <Img
                    alt="blog post featured image"
                    fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                  />
                </Link>
                <Link
                  className="post-content"
                  style={{ boxShadow: `none` }}
                  to={node.fields.slug}
                >
                  <header>
                    <div className="preview-image show-on-mobile">
                      <Img
                        alt="blog post featured image"
                        fluid={
                          node.frontmatter.featuredImage.childImageSharp.fluid
                        }
                      />
                    </div>
                    <h3>{title}</h3>
                  </header>
                  <section>
                    <div style={{ boxShadow: `none` }} to={node.fields.slug}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </div>
                  </section>
                </Link>
              </div>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    owgtowg: file(relativePath: { eq: "Logo_transparent.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
